
export const environment = {
    production: true,
    GOOGLE_ANALYTICS_KEY: 'G-G3BFCFHJFH',
    PORT: 80,
    appUrls:{
      ENV_NAME: 'web_dev',
      ENV_TYPE: 'PRODUCTION',
      AUTH_PORT: 4020,
      IMAGES_SERVER_IP: 'https://linqura.app',
      API_WEB_SOCKET_ADDRESS: 'https://ws-api.linqura.app',
      USERS_WEB_SOCKET_ADDRESS: 'https://ws-users.linqura.app',
      DATA_ADMIN_WEB_SOCKET_ADDRESS: 'https://ws-admin.linqura.app',
      IMAGES_SERVER_URL: "https://linqura.app/image-upload",
      GOOGLE_MAPS_API_KEY: 'AIzaSyBWhX_0F3uYpuuC1OBG5DB5v40nXrKL60o', //'AIzaSyALtSWzBVUVVfHx6Gv4dSG95q-zjw3Voz0'
      SOC_AUTOMATION_SERVER_URL: "https://linqura.app",
      AUTH_URL: 'https://linqura.app',
      KILLBILL_HTTPS_SERVER: "https://linqura.app",
      USERS_WEB_SOCKET_PORT: 3641,
      API_WEB_SOCKET_PORT: 3691,
      DATA_ADMIN_WEB_SOCKET_PORT: 3801,
      SMALL_LOGO: "https://sybl-site.s3.us-east-1.amazonaws.com/Linqura_White.png",
      LARGE_LOGO: "https://sybl-site.s3.us-east-1.amazonaws.com/Linqura_White.png",
      IMAGES_URL:"https://sybl-site.s3.us-east-1.amazonaws.com/",
      AVATAR_URL:"https://s3.amazonaws.com/images.thestateofcomedy.com/avatars/",
      VIMEO_TOKEN: "7aec61487eb4f303ef3c90fdd1c09fc6",   //081b5a3aff6c0ec88c70efeecd9d54bf
      BASE_IP_ADDRESS: "https://linqura.app", //"https://127.0.0.1"
      DEFAULT_AVATAR: "default.svg",
      REGISTERED_AVATAR: "registered.svg",
      API_HTTPS_SERVER: "https://linqura.app",
      USERS_HTTPS_SERVER: "https://linqura.app",
      INSTALL_PATH:"sybl",
      FRONTEND_VERSION:"linqura-frontend.beta.2.0.6",
      API_SERVER_VERSION:"linqura-api-server.beta.2.0.6",
      AUTH_SERVER_VERSION:"linqura-auth-server.beta.2.0.6",
      USERS_SERVER_VERSION:"linqura-users-server.beta.2.0.6",
      IMAGES_SERVER_VERSION:"images-server.beta.2.0.6",
      STRIPE_PUBLIC_KEY: 'pk_test_51OUDORFCagLFE1CPXmhlwA93O5AGOQ5rLkWPgax4WoZ0N0IZZoJJUcKY4BvyQhPnnuTdd3iv02GCoCIAA8ogSYbV00Nw2hnMW9',
      S3_SYBL_SVGS_BUCKET_URL: 'https://sybl-site.s3.us-east-1.amazonaws.com/assets/svgs/',
      STRIPE_CHECKOUT_URL: 'https://buy.stripe.com/test_aEUeYtfWmcFQ5ry6oo'
    }
  };

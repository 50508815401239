<div style="margin: auto; background-color: #001b26; font-family: 'aileron'">
  <div class="box-info-holder">
    <div class="box-info-left">
      <div class="box-info-header">Follow</div>
      <div class="box-info-contact-info">
        @if(twitterId){


        }
        @if(linkedInId){
          <div>
            <img
              matRipple
              class="social-media-logo"
              (click)="clickedLinkedIn()"
              src="{{ linkedInIcon }}"
            />
          </div>

        }
        @if(instagramId){

        } @if(youTubeId){

        <div>
          <img
            matRipple
            class="social-media-logo"
            (click)="clickedYouTube()"
            src="{{ youTubeIcon }}"
          />
        </div>
      }
      </div>
    </div>

    <div class="box-info-right">
      <div class="box-info-header">&nbsp;Site Links</div>

      <div class="box-info-site-links">
        <div class="site-link-text" (click)="clickedAboutUs()">About</div>
        <div class="site-link-text" (click)="clickedContactUs()">Contact</div>
        <div class="site-link-text" (click)="clickedCorrections()">
          Corrections
        </div>
        <div class="site-link-text" (click)="clickedConditions()">
          Terms & Conditions
        </div>
      </div>

      <div style="margin-top: 20px;">For support and cancellations please contact support&#64;linqura.ai</div>
    </div>
  </div>

  <div
    style="
      color: white;
      font-size: 1rem;
      margin-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;
    "
  >
    ©2025 by Linqura
  </div>
</div>

export enum SyblAccountStatusEnum {
  'All'="ALL",
  Invited="INVITED",
  Unverified="UNVERIFIED",
  Verified="VERIFIED",
  Active="ACTIVE",
  Inactive="INACTIVE",
  Deleted="DELETED"
}

export enum SyblAccountStatusEnumUserFacing {
  //Invited="INVITED",
  //Unverified="UNVERIFIED",
  //Verified="VERIFIED",
  Active="ACTIVE",
  Inactive="INACTIVE",
  Deleted="DELETED"
}


export enum SyblLicenseTypeEnum {
  /*Individual_Trial="INDIVIDUAL_TRIAL",
  Individual="INDIVIDUAL",
  Team_Trial="TEAM_TRIAL",
  Team="TEAM",
  */
  /*  Delete Above After Making Changes
  */
  Premier="PREMIER",
  PlusEarlyAdopter="PLUS_EARLY_ADOPTER",
  Plus="PLUS",
  Enterprise="ENTERPRISE",
  Enterprise_Trial="ENTERPRISE_TRIAL"
}


export enum SyblPermissionsEnum {
//    'Guest User'="GUEST",
//    'Regular User'="REGULAR",
  'All'="ALL",
  'Co-Pilot User'="SYBL",
  'Account Admin'="ACCOUNT_ADMIN",
  'Manager'="MANAGER",
  'Owner'="OWNER",
  //  'Super'="SUPER",
}

export enum SyblAgencyEmployeeCount{
  '1-5'='ONE_TO_FIVE',
  '6-10'='SIX_TO_TEN',
  '11-20'='ELEVEN_TO_TWENTY',
  '20+'='TWENTY_PLUS',
}

export enum SyblAgencyYearlyRevenue{
  '0-1 million'="ZERO_TO_ONE_MILLION",
  '1-2 million'="ONE_MILLION_TO_TWO_MILLION",
  '2-5 million'="TWO_MILLION_TO_FIVE_MILLION",
  '5-10 million'="FIVE_MILLION_TO_TEN_MILLION",
  '10+ million'="TEN_MILLION_PLUS",
}
